import React from "react"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ className, children }) => {
  return (
    <div className={className}>
      <Header/>
      {/* <div className="position-relative mt-n12 mb-12 z-n1">
        <div className="position-absolute bg-cover w-100 ">
          <img src={"images/new-theme/deccor_top.svg"} alt="" />
        </div>
      </div> */}

      {children}
      <Footer />
    </div>
  )
}

export default Layout
